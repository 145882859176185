import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

import Image from '../../../../../../gatsby-theme-husky/src/common/Image';
import { ListingCardProps } from '../../../../../../gatsby-theme-husky/src/components/Listing/models';
import { DangerouslySetInnerHtml } from '../../../../../../gatsby-theme-husky/src/layout';
import { gridColumnsCount } from '../../../../../../gatsby-theme-husky/src/utils/constants';

const ArticlePromoListingCard: FC<ListingCardProps> = ({
  type,
  title,
  description,
  ctaLabel,
  image,
  promoImage,
  url,
  cardsInRow,
}) => {
  const colSpan = gridColumnsCount / cardsInRow;

  return (
    <Col as="li" lg={colSpan} className="listing-card-wrapper" data-testid="listing-card">
      <Link
        className={`listing-card listing-card--${type}`}
        to={url}
        aria-label={`${ctaLabel ? `${title} - ${ctaLabel}` : title}`}
      >
        <Image
          imageData={promoImage || image}
          alt={title || ctaLabel}
          className="listing-card__image"
        />
        <div className="listing-card__container">
          <DangerouslySetInnerHtml
            element="h3"
            className="listing-card__title heading-link heading-link--lvl4"
            html={title || ctaLabel}
          />
          <DangerouslySetInnerHtml
            element="p"
            className="listing-card__description"
            html={description}
          />
        </div>
      </Link>
    </Col>
  );
};

export default ArticlePromoListingCard;
