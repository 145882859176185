import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'gatsby';

import Image from '../../../../../../gatsby-theme-husky/src/common/Image';
import { ProductListingCardProps } from '../../../../../../gatsby-theme-husky/src/components/Listing/models';
import { DangerouslySetInnerHtml } from '../../../../../../gatsby-theme-husky/src/layout';
import { gridColumnsCount } from '../../../../../../gatsby-theme-husky/src/utils/constants';

const ProductListingCard: FC<ProductListingCardProps> = ({
  type,
  title,
  description,
  disclaimer,
  image,
  url,
  tags,
  findCallOutTag,
  cardsInRow = 1,
}) => {
  const colSpan = gridColumnsCount / cardsInRow;
  const callOutTag = findCallOutTag(tags);

  const wrapCompProps = {
    className: `listing-card-wrapper listing-card-wrapper--${type}`,
    'data-testid': 'listing-card',
  };

  return (
    <Col as="li" xl={colSpan} md={6} {...wrapCompProps}>
      <Link
        className={`listing-card listing-card--${type}`}
        to={url}
        aria-label={`${callOutTag ? `${title} - ${callOutTag}` : title}`}
      >
        {callOutTag ? (
          <div className="listing-card__call-out" data-testid="listing-card__call-out">
            {callOutTag}
          </div>
        ) : null}
        <Image imageData={image} alt={title || ''} className="listing-card__image" />
        <Row noGutters className="listing-card__top-container">
          <Col className="listing-card__title-container">
            <h3 className="listing-card__title heading-link heading-link--lvl5">{title}</h3>
          </Col>
        </Row>
        <Row className="listing-card__bottom-container">
          <Col className="listing-card__bottom-container-col">
            <DangerouslySetInnerHtml
              element="p"
              className="listing-card__description"
              html={description}
            />
            {disclaimer ? <p className="listing-card__disclaimer">{disclaimer}</p> : null}
          </Col>
        </Row>
      </Link>
    </Col>
  );
};

export default ProductListingCard;
