import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { navigate } from 'gatsby';

import Image from '../../../../../../gatsby-theme-husky/src/common/Image';
import { ProductListingCardProps } from '../../../../../../gatsby-theme-husky/src/components/Listing/models';
import { DangerouslySetInnerHtml } from '../../../../../../gatsby-theme-husky/src/layout';

const ProductListingCard: FC<ProductListingCardProps> = ({
  type,
  title,
  description,
  disclaimer,
  image,
  url,
  tags,
  isCarouselMoving,
  findCallOutTag,
  onFocus,
}) => {
  const callOutTag = findCallOutTag(tags);

  const onClick = (e) => {
    if (isCarouselMoving) e.preventDefault();
    // ignore clicks with modifier keys... See https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-link/src/index.js#L215
    else if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) return true;
    else navigate(url);
  };

  return (
    <div
      className={`listing-card-wrapper listing-card-wrapper--${type}`}
      data-testid="listing-card"
    >
      <a
        className={`listing-card listing-card--${type}`}
        onClick={onClick}
        href={url}
        onFocus={onFocus}
        aria-label={`${callOutTag ? `${title} - ${callOutTag}` : title}`}
      >
        {callOutTag ? (
          <div className="listing-card__call-out" data-testid="listing-card__call-out">
            {callOutTag}
          </div>
        ) : null}
        <Image imageData={image} alt={title || ''} className="listing-card__image" />
        <Row noGutters className="listing-card__top-container">
          <Col className="listing-card__title-container">
            <h3 className="listing-card__title heading-link heading-link--lvl5">{title}</h3>
          </Col>
        </Row>
        <Row className="listing-card__bottom-container">
          <Col className="listing-card__bottom-container-col">
            {description ? (
              <DangerouslySetInnerHtml
                element="p"
                html={description}
                className="listing-card__description"
              />
            ) : null}
            <p className="listing-card__disclaimer">{disclaimer}</p>
          </Col>
        </Row>
      </a>
    </div>
  );
};

export default ProductListingCard;
